import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import Head from "../components/head"
import returns from "../images/about/returns.jpg"
// import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"


const liStyle = {
  display: "list-item",
  listStyleType: "disc",
  paddingBottom: ".5rem",
  marginLeft: "2rem",
  fontSize: "14px",
  color: "#777777",
}
const Returns = props => {
  return (
    <Layout>
      <Head title="Returns" description="ThrowThings.com returns policies" />
      <Container>
        <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-section">
                  {/* <Img fluid={props.data.imageOne.childImageSharp.gatsbyImageData} alt="U-turn sign" /> */}
                  <img
                    src={returns}
                    className="img-fluid lazyload"
                    alt="U-turn sign"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <h4>ThrowThings.com Returns & Exchanges Policies</h4>
                <p>
                  In order to help keep pricing as low as possible for our
                  customers and clients we have instituted the following
                  policies on returns and exchanges. Please note that different
                  products have different returns and exchange policies. In
                  cases where returns are processed, refunds for purchases made
                  by credit card or PayPal are made to the same account whenever
                  possible. In the event that we are not able to process the
                  refund based on the original method of payment the refund may
                  be made by check. Refunds for purchases made with cash, checks
                  or money orders are made by check.
                </p>
                <p>
                  In the case of a return we are not able to refund the original
                  shipping amount or the cost of shipping the item(s) back to
                  us. In the case of an exchange additional shipping charges
                  will apply for shipping the replacement item(s) to the
                  customer. When shipping an item to us we suggest that you send
                  the item(s) by FedEx Ground, UPS Ground, Priority Mail With
                  Delivery Confirmation, or some other method that allows for
                  tracking. We are not able to assume responsibility for the
                  item until we receive it. You may also wish to insure the
                  package. Unless otherwise instructed, returns should be sent
                  to:
                </p>
                <p>
                  <div>Returns & Exchanges</div>
                  <div>ThrowThings.com</div>
                  <div>231 W Main St</div>
                  <div>Ravenna, OH 44266</div>
                </p>
                <p>
                  Please include a copy of your original receipt/packing slip
                  and a specific note indicating why the package is being sent.
                </p>
                <p>
                  Most items from our "Things To Throw," "Throw Your Voice,"
                  "Throw Backs," "Throw Up," "Throw A Party," and "ThrowThings
                  Things" departments may be returned or exchanged subject to
                  the following restrictions:
                </p>
                <ul>
                  <li style={liStyle}>
                    Items must be received by us within 30 days of purchase.
                  </li>
                  <li style={liStyle}>
                    Items must be returned in brand new, original condition.
                  </li>
                  <li style={liStyle}>
                    Items shipped in packaging must be returned having never
                    been removed from the packaging. Packing examples include
                    blister cards, carrying cases/totes, plastic wrapping, bags,
                    boxes, etc.
                  </li>
                  <li style={liStyle}>
                    All pieces and accessories must be included.
                  </li>
                  <li style={liStyle}>
                    If for any reason it is our opinion the item(s) can not be
                    sold as new to another customer we are not able to accept a
                    return/exchange. (Please take an objective look at your item
                    to see if you would have been happy had you received it in
                    the current condition as a new product.)
                  </li>
                </ul>
                <p>Basic ventriloquist dolls must be returned having never been removed from their carrying case. Upgraded dummies must be returned having never been removed from the plastic wrapping that they are shipped in.</p>
                <p>Professional ventriloquist figures are not returnable.</p>
                <p>Items from our "Throw Your Name Around" department are generally not returnable or exchangeable as they are custom imprinted promotional products. Once imprinted with your logo/message/information the items are no longer of value to another customer/client. In some cases we may accept returns or exchanges for items from this department however restocking charges may apply.</p>
                <p>Gift Certificates, Upgrade Certificates, and "Throw Your Money Away" certificates are not returnable. Gift Certificates and Upgrade Certificates are exchanged for products and services as specified on the certificate.</p>
                <p>Special Order items are not returnable or exchangeable.</p>
                <h4>Damaged Or Defective Items</h4>
                <p>We take pride in the consistent quality of the products that we ship and our shipping partners generally do an excellent job of getting packages to our customers and clients in excellent condition. Unfortunately though it is not impossible for a problem to arise. In the highly unlikely event that a product is received by a customer in damaged or defective condition we will repair or replace it but you need to let us know as below. If for some reason we are not able to repair or replace the item(s) a full or partial refund may be issued (depending on the circumstances.)</p>
                <p><strong>DAMAGE IN SHIPPING MUST  BE REPORTED TO US WITHIN 48 HOURS OF RECEIPT.</strong> In the case of damage in shipping we will resolve the issue as soon as possible but will need to wait for the shipping company to complete it's investigation and verify the damage. Please keep all packing materials, items, etc.</p>
                <p>In the event that an item is defective we must be notified within 30 days of purchase except for items from our "Throw Your Name Around" department. These items must have defects reported within 48 hours of receipt.</p>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  );
}

export default Returns

export const pageQuery = graphql`{
  imageOne: file(relativePath: {eq: "about/returns.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
}
`;

